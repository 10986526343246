<template>
  <el-dialog
    v-model="winShow"
    width="900px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="t('product.show_product')">
    <div class="form" style="width:100% !important;">
      <div>{{ t('enterprise.name') }}：{{ enterpriseInfo.enterprise || '-' }}</div>
      <div class="products_wrap">
        <div class="product_label">{{ t('routes.enterprise_product') }}:</div>
        <div class="product_list">
          <!-- 选择产品 -->
          <div class="product_select" v-if="openProSelect">
            <el-select class="product_item" v-model="product_id" filterable :placeholder="t('form.inputPlaceholder')"
              style="flex: 1;">
              <el-option v-for="(item, index) in selectProList" @change="checkExist" :key="index" :label="item.label"
                :value="item.value" />
            </el-select>
            <el-button class="del_pro" @click="creatPro" link type="primary">{{ t('form.save') }}</el-button>
            <el-button @click="cancelPro" link type="primary">{{ t('form.cancel') }}</el-button>
          </div>
          <div class="addpro">
            <el-button v-if="tableInfo.data.length" type="primary" :disabled="openProSelect"
              @click="openProSelect = true">{{ t('product.add_product') }}</el-button>
            <el-button :disabled="!delList.length" type="primary" plain @click="deleteData">{{ t('form.delete') }}</el-button>
          </div>
        </div>
      </div>
      <div v-if="tableInfo.data.length" class="products_wraps">
        <div class="product_label">{{ t('routes.enterprise_product') }}:</div>
        <div class="product_list">
          <!-- 企业产品 -->
          <custem-table :tableInfo="tableInfo" :tableLoading="tableLoading" @onSelectData="onSelectData" @getList="getProduct" />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeWin">{{ t('form.close') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, nextTick, inject } from 'vue'
import { productManagement } from '@/utils/api'
import { ElMessage } from 'element-plus'
const winShow = ref(false)
const t = inject('t')
const product_id = ref('') // 选择产品id
const selectProList = ref([]) // 选择框产品列表
const openProSelect = ref(false) // 添加产品选择框
const delList = ref([]) // 删除数据id列表
const enterpriseInfo = ref({
  enterprise: "", // 企业名称
  id: '' // 企业id
})
const tableLoading = ref(false)

// 打开弹框
const openWin = (id, name, sales_user_id) => {
  winShow.value = true
  enterpriseInfo.value.enterprise = name
  enterpriseInfo.value.id = id
  getProduct()
  getProductList(sales_user_id)
}

// 关闭弹框
const closeWin = () => {
  winShow.value = false
  enterpriseInfo.value.enterprise = ""
  enterpriseInfo.value.id = ""
  delList.value = []
}

// 查看产品
const getProduct = async (search = {}) => {
  tableLoading.value = true
  try {
    const res = await productManagement.EnterpriseProduct({
      ...search,
      filter_enterprise_id: enterpriseInfo.value.id
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

// 取消添加
const cancelPro = () => {
  product_id.value = ""
  openProSelect.value = false
}

// 检测企业下是否存在选中产品
const checkExist = () => {
  if (!!!product_id.value) {
    ElMessage.warning(t('form.selectPlaceholder'))
    return
  }
  if (!!!enterpriseInfo.value.id) {
    ElMessage.warning(t('enterprise.no_data'))
    return
  }
  return new Promise((resolve, reject) => {
    productManagement.EnterpriseProduct({
      filter_product_id: product_id.value,
      filter_enterprise_id: enterpriseInfo.value.id
    }).then(res => {
      if (!res.items.length) {
        resolve(1)
      } else {
        ElMessage.warning(t('enterprise.exist_product'))
        resolve(0)
      }
    })
  })
}

// 获取企业所属销售产品列表
const getProductList = (user_id) => {
  productManagement.SalesProductList({
    filter_user_id: user_id,
    is_paginate: false
  }).then(res => {
    let arr = []
    res.items.forEach(item => {
      arr.push({
        label: `${item.product.deployment.name}/${item.product.category.name}/${item.product.name}`,
        value: item.product.id
      })
    });
    selectProList.value = arr;
  })
}

// 创建产品
const creatPro = async () => {
  if (!!!product_id) return
  const res = await checkExist() // 检测企业下是否存在选中产品
  if (res) {
    productManagement.EnterpriseProductCreate({
      product_id: product_id.value,
      enterprise_id: enterpriseInfo.value.id
    }).then(res => {
      ElMessage.success(t('form.add_success'))
      getProduct(enterpriseInfo.value.id)
      cancelPro()
    })
  }
}

// ================== 删除相关 ==================
// 执行删除
const deleteData = async () => {
  let arr = []
  delList.value.forEach(item => {
    arr.push(
      // 批量提交
      productManagement.EnterpriseProductDelete(item)
    )
  })
  await nextTick()
  Promise.all(arr).then(async () => {
    ElMessage.success(t('form.del_success'))
    delList.value = []
    getProduct(enterpriseInfo.value.id)
  }).catch(error => {
    ElMessage.error(t('form.del_failed'))
  })
}

// 行内删除
const singleDel = (data) => {
  delList.value = [];
  delList.value.push(data.id)
  deleteData() // 执行删除
}

// 表格多选事件
const onSelectData = (data) => {
  delList.value = data.map(item => {
    return item.id
  })
}

// ================== 表格相关 ==================
const tableInfo = reactive({
  selection: true, // 选择框列
  header: [{ // 产品大类
    key: 'deployment',
    label: t('product.product_category'),
    width: '160px',
    custem: (data) => {
      return data.product ? data.product.deployment.name : '-'
    }
  },
  { // 产品类型
    key: 'category',
    label: t('product.product_type'),
    width: '160px',
    custem: (data) => {
      return data.product ? data.product.category.name : '-'
    }
  },
  { // 产品名称
    key: 'product_name',
    label: t('product.product_name'),
    custem: (data) => {
      return data.product ? data.product.name : '-'
    }
  },
  { // 产品编码
    key: 'code',
    label: t('product.product_code'),
    custem: (data) => {
      return data.product ? data.product.code : '-'
    }
  },
  {
    key: 'login_at',
    label: t('table.action'),
    width: '80px',
    fixed: 'right',
    handleFun: [
      {
        label: t('form.delete'),
        type: 'delete',
        content: t('form.sure_del'),
        fn: singleDel
      }
    ]
  }],
  data: []
})

defineExpose({
  openWin: openWin
})

</script>

<style lang="scss">
.products_wrap {
  display: flex;
  align-items: flex-start;
}

.product_label {
  margin-top: 20px;
  min-width: 62px;
}

.product_list {
  margin-left: 10px;
  flex: 1;
  margin-top: 15px;

  .products_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .product_item {
      background: #ededed;
      padding-left: 10px;
      box-sizing: border-box;
      line-height: 32px;
      flex: 1;
    }
  }
}

.del_pro {
  cursor: pointer;
  width: 50px;
  min-width: 50px;
}

.product_select {
  width: 60%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.nodata {
  margin-top: 20px;
  margin-left: 10px;
}

.addpro {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
</style>