<template>
  <div>
    <div class="zhanwei"></div>
    <el-form
      :model="formData.form"
      :rules="formData.rules"
      label-position="right"
      label-width="140px"
      ref="form">
      <!-- 用户登录名 -->
      <el-form-item :label="t('user.username')" prop="name">
        <el-input
          v-model="formData.form.name"
          autocomplete="new-password"
          :disabled="!!props.edit_id"
          :placeholder="t('form.inputPlaceholder')" />
      </el-form-item>
      <!-- 登录密码 -->
      <el-form-item
        :label="t('user.password')"
        prop="password">
        <el-input
          v-model="formData.form.password"
          autocomplete="new-password" 
          :placeholder="t('form.inputPlaceholder')"
          show-password />
      </el-form-item>
      <!-- 确认密码 -->
      <el-form-item
        v-if="!props.edit_id"
        :label="t('user.confirm_password')"
        prop="password1"
        required>
        <el-input
          v-model="formData.form.password1"
          auto-complete="new-password" 
          :placeholder="t('form.inputPlaceholder')"
          show-password />
      </el-form-item>
      <!-- 用户昵称 -->
      <el-form-item
        :label="t('user.nick_name')"
        prop="nick_name">
        <el-input
          v-model="formData.form.nick_name"
          :placeholder="t('form.inputPlaceholder')" />
      </el-form-item>
      <!-- 联系人姓名 -->
      <el-form-item :label="t('user.liaison_name')" prop="liaison_man">
        <el-input v-model="formData.form.liaison_man" :placeholder="t('form.inputPlaceholder')"  />
      </el-form-item>
      <!-- 销售代管 -->
      <el-form-item :label="t('user.custody')" prop="tutelage">
        <div>
          <div><el-switch v-model="formData.form.tutelage" /></div>
          <div class="tips">{{ t('user.custody_tip') }}</div>
        </div>
      </el-form-item>
      <!-- 联系人手机号 -->
      <el-form-item :label="t('user.liaison_phone')" prop="liaison_phone">
        <el-input v-model="formData.form.liaison_phone" type="number" :placeholder="t('form.inputPlaceholder')" />
      </el-form-item>
      <!-- 联系人邮箱 -->
      <el-form-item :label="t('user.liaison_mail')" prop="liaison_mail">
        <el-input v-model="formData.form.liaison_mail" :placeholder="t('form.inputPlaceholder')" />
      </el-form-item>
      <!-- 备注 -->
      <el-form-item :label="t('user.notes')">
        <el-input type="textarea" :rows="6" v-model="formData.form.comment" :placeholder="t('form.inputPlaceholder')" />
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="emit('prevStep')" type="primary">{{ t('form.prev') }}</el-button>
      <el-button @click="emit('createEnterpise')" type="primary">{{ t('form.submit') }}</el-button>
      <el-button @click="cancel">{{ t('form.cancel') }}</el-button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, inject } from 'vue'
import { userManagement } from '@/utils/api'
import { ElMessage } from 'element-plus'

const emit = defineEmits(['onClose', 'createEnterpise', 'prevStep', 'getList'])
const t = inject('t')
const form = ref()
const props = defineProps({
  winShow: {
    type: Boolean,
    default: false
  }
})

watch(() => props.winShow, val => {
  if (!!!val && form.value)
    form.value.resetFields()
}, { immediate: true, deep: false })


const formData = ref({
  form: {
    comment: ''
  },
  rules: {
    name: [{
      trigger: ['blur', 'change'],
      required: true,
      validator: async (rule, value, callback) => {
        if (!!!value) {
          callback(new Error(t('form.inputPlaceholder')))
        } else {
          const res = await userManagement.checkUser({
            type: 'name',
            name: value
          })
          if (res.success) {
            callback()
          } else {
            callback(new Error(res.reason))
          }
        }
      }
    }],
    password: [{ required: true, message: t('form.inputPlaceholder') }],
    nick_name: [{ required: true, message: t('form.inputPlaceholder') }],
    liaison_man: [{ required: true, message: t('form.inputPlaceholder') }],
    liaison_mail: [
      { required: true, message: t('form.inputPlaceholder') },
      { type: 'email', message: t('form.error_mail') }
    ],
    liaison_phone: [{ required: true, message: t('form.inputPlaceholder') }],
    password1: [{
      trigger: ['blur', 'change'],
      validator: (rule, value, callback) => {
        if (!!!value) {
          callback(new Error(t('form.inputPlaceholder')))
        } else if (value !== formData.value.form.password) {
          callback(new Error(t('form.pwdMessage')))
        } else {
          callback()
        }
      }
    }]
  }
})

// 创建用户请求
const createEnterpiseUser = (enterprise_id, sales_id) => {
  form.value.validate(valid => {
    if (valid) {
      let data = formData.value.form
      const subData = {
        ...data,
        parent_id: sales_id,
        enterprise_id: enterprise_id,
        params: {
          tutelage: data.tutelage,
          comment: data.comment,
          liaison_man: data.liaison_man,
          liaison_mail: data.liaison_mail,
          liaison_phone: data.liaison_phone
        },
        role_id: 'enterprise'
      }
      delete subData.tutelage;
      delete subData.liaison_phone;
      delete subData.liaison_mail;
      delete subData.comment;
      delete subData.liaison_man;
      userManagement.roleUserCreate(subData)
        .then(res => {
          ElMessage.success(t('form.create_success'))
          emit('getList')
          cancel()
        })
    }
  })
}


// 重制表单，清空校验
const resteForm = () => {
  form.value.resetFields()
}

// 取消
const cancel = () => {
  resteForm()
  emit('onClose')
}

defineExpose({
  createEnterpiseUser: createEnterpiseUser,
  resteForm: resteForm
})
</script>
