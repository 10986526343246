<template>
  <el-dialog
    v-model="winShow"
    :close-on-click-modal="false"
    :title="t('enterprise.detail')"
    :close-on-press-escape="false"
    @close="activeName = 1"
    width="900px">
    <custem-detail
      :loading="detailLoading"
      :descriptions="descriptions" />
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane :label="t('user.enterprise_user')" :name="1"></el-tab-pane>
        <el-tab-pane :label="t('routes.enterprise_product')" :name="2"></el-tab-pane>
        <el-tab-pane v-if="$userRole(['GeneralAdmin'])" :label="t('user.sales')" :name="3"></el-tab-pane>
      </el-tabs>
      <!-- 企业用户 -->
      <EnterpriseUser v-if="activeName === 1" ref="enterpriseUserRef" />
      <!-- 企业产品表格 -->
      <custem-table
        v-else
        :tableInfo="tableInfo"
        :tableLoading="tableLoading"
        height="400px"
        @getList="getList" />
  </el-dialog>
</template>

<script setup>
import { ref, inject, reactive, getCurrentInstance, watch, nextTick, computed } from 'vue'
import { enterpriseManagement, productManagement } from '@/utils/api'
import EnterpriseUser from '@/views/Users/Enterprise'

const t = inject('t')
const { proxy } = getCurrentInstance()
const winShow = ref(false)
const detailLoading = ref(false)
const activeName = ref(1)
const enterpriseUserRef = ref()
const tableLoading = ref(false)
const detail_id = ref(null)
const descriptions = reactive([
  { // 企业名称
    label: t('enterprise.name'),
    prop: 'name'
  },
  { // 创建者姓名
    label: t('enterprise.creator'),
    prop: 'creator'
  },
  { // 统一社会代码
    label: t('enterprise.social_code'),
    prop: 'social_code',
    span: 2
  },
  { // 企业联系人姓名
    label: t('user.enterprise_Liaison'),
    prop: 'liaison_man'
  },
  { // 企业联系人手机号
    label: t('user.enterprise_liaison_phone'),
    prop: 'phone'
  },
  { // 企业联系人邮箱
    label: t('user.enterprise_liaison_mail'),
    prop: 'mail'
  },
  { // 企业类型
    label: t('enterprise.type'),
    prop: 'label'
  },
  { // 企业状态
    label: t('enterprise.status'),
    prop: 'status',
    span: 2
  },
  { // 备注
    label: t('user.notes'),
    prop: 'comment',
    span: 2
  }
])
const tableInfo = computed(() => {
  if (activeName.value === 1) return {}
  if (activeName.value === 2) {
    return {
      header: [
        { // 产品大类
          key: 'deployment_name',
          label: t('product.product_category'),
          width: '160px',
          custem: (data) => {
            return data.product ? data.product.deployment.name : '-'
          }
        },
        { // 产品类型
          label: t('product.product_type'),
          key: 'category_name',
          width: '120px',
          custem: (data) => {
            return data.product ? data.product.category.name : '-'
          }
        },
        { // 产品名称
          key: 'product_name',
          label: t('product.product_name'),
          custem: (data) => {
            return data.product ? data.product.name : '-'
          }
        },
        { // 产品编码
          key: 'code',
          label: t('product.product_code'),
          custem: (data) => {
            return data.product ? data.product.code : '-'
          }
        }
      ],
      data: []
    }
  }
  return {
    header: [
      { // 销售名称
        key: 'name',
        label: t('user.sales_name'),
        custem: (data) => {
          return data.sale.name || '-'
        }
      },
      { // 转入时间,
        label: t('user.entry_time'),
        key: 'start_at',
        minWidth: '180px',
        sortable: true,
        sortBy: (data) => {
          return data.start_at
        }
      },
      { // 转出时间
        key: 'end_at',
        label: t('user.exit_time'),
        minWidth: '180px',
        sortable: true,
        sortBy: (data) => {
          return data.end_at
        }
      }
    ]
  }
})

const getList = () => {
  if (activeName.value === 2) {
    getEnterpriseProduct()
  } else if (activeName.value === 3) {
    getEnterpriseSales()
  }
}
// 打开弹框
const openWin = (data) => {
  winShow.value = true
  getEnterpriseDetail(data.id)
}
// 获取企业详情接口
const getEnterpriseDetail = async (id) => {
  detail_id.value = id
  detailLoading.value = true
  try {
    const res = await enterpriseManagement.EnterpriseDetail(detail_id.value)
    descriptions.forEach(item => {
      if (item.prop === 'creator') { // 创建人
        item.value = res.sales && res.sales.name || '-'
      } else if (item.prop === 'label') { // 企业类型
        item.value = res.label === '1' ? t('enterprise.testing') : res.label === '2' ? tt('enterprise.scan') : t('enterprise.general')
      } else if (item.prop === 'status') {
        item.value = proxy.$Enterpriseformat(res.status, 'html')
      } else {
        item.value = res.params && res.params[item.prop] || res[item.prop] || '-'
      }
    })
    detailLoading.value = false
    await nextTick()
    // 获取企业用户列表
    enterpriseUserRef.value.getList({ filter_enterprise_id: detail_id.value })
  } catch (error) {
    detailLoading.value = false
  }
}

// 获取企业所属销售列表
const getEnterpriseSales = async () => {
  tableLoading.value = true
  try {
    const res = await enterpriseManagement.enterpriseSale({
      filter_enterprise_id: detail_id.value
    })
    tableSalesInfo.data = res.items
    tableSalesInfo.totalPage = res.total
    tableSalesInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}
// 获取企业产品列表
const getEnterpriseProduct = async () => {
  tableLoading.value = true
  try {
    const res = await productManagement.EnterpriseProduct({
      filter_enterprise_id: detail_id.value
    })
    tableInfo.data = res.items;
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

watch(() => activeName.value, async (val) => {
  if (val === 1) {
    await nextTick()
    enterpriseUserRef.value.getList({ filter_enterprise_id: detail_id.value })
  } else {
    getList()
  }
}, { deep: true })

defineExpose({
  openWin
})
</script>