<template>
  <el-dialog
    v-model="winShow"
    :close-on-click-modal="false"
    :title="t('enterprise.detail')"
    :close-on-press-escape="false"
    width="900px">
    <el-descriptions :column="2" border :title="t('assign.application_info')">
      <template v-for="(item, index) in createrInfo" :key="index">
        <template v-if="item.custem">
          <el-descriptions-item :label="item.label" v-if="!!!item.hidden" :span="item.span ? item.span : ''">
            <div v-html="item.custem(createrDetail[item.prop]) || '-'"></div>
          </el-descriptions-item>
        </template>
        <template v-else>
          <el-descriptions-item :label="item.label" v-if="!!!item.hidden" :span="item.span ? item.span : ''">
            {{ enterpriseDetail[item.prop] || '-' }}
          </el-descriptions-item>
        </template>
      </template>
    </el-descriptions>
    <el-form :model="formData" :rules="rules" style="margin-top: 16px;" ref="form">
      <el-descriptions :column="2" border :title="t('enterprise.info')">
        <template v-for="(item, index) in descriptions" :key="index">
          <template v-if="!!!item.hidden">
            <template v-if="item.custem">
              <el-descriptions-item :label="item.label" v-if="!!!item.hidden" :span="item.span ? item.span : ''">
                <div v-html="item.custem(enterpriseDetail[item.prop]) || '-'"></div>
              </el-descriptions-item>
            </template>
            <template v-else>
              <el-descriptions-item :label="item.label" v-if="item.prop == 'name'" :span="item.span ? item.span : ''">
                <el-form-item prop="name">
                  <el-input :placeholder="t('form.inputPlaceholder')" v-model="formData.name" />
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :label="item.label" v-else :span="item.span ? item.span : ''">
                {{ enterpriseDetail[item.prop] || '-' }}
              </el-descriptions-item>
            </template>
          </template>
        </template>
      </el-descriptions>
      <el-form-item prop="code" :label="t('enterprise.code')" style="margin-top: 16px;">
        <el-input :placeholder="t('form.inputPlaceholder')" v-model="formData.code" />
      </el-form-item>
    </el-form>
    <div class="dialog-footer" style="margin-top: 16px;">
      <el-button @click="closeWin">{{ t('form.cancel') }}</el-button>
      <el-button @click="onSubmit" type="primary">{{ t('form.confirm') }}</el-button>
      <el-button @click="onRefuse" type="primary">{{ t('form.reject') }}</el-button>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, inject } from 'vue'
import { enterpriseManagement } from '@/utils/api'
import { ElMessageBox, ElMessage } from 'element-plus'
import dayjs from 'dayjs'

const t = inject('t')
const winShow = ref(false)
const form = ref()
const enterpriseDetail = ref({})
const createrDetail = ref({})
const formData = ref({})
const emit = defineEmits(['getList'])
const rules = ref({
  name: [{ required: true, message: t('form.emptyErrMsg') }],
  code: [
    { required: true, message: t('form.emptyErrMsg'), trigger: 'blur' },
    { min: 2, max: 20, message: t('enterprise.code_length'), trigger: 'blur' },
    { pattern: /^[a-zA-Z-]+$/, message: t('enterprise.code_type'), trigger: 'blur' }
  ]
})

const createrInfo = reactive([
  { // 创建时间
    label: t('table.creation_time'),
    prop: "created_at",
    custem: () => {
      return Object.keys(enterpriseDetail.value).length ? dayjs(enterpriseDetail.value.created_at).format('YYYY-MM-DD HH:mm:ss') : '-'
    }
  },
  { // 创建人姓名
    label: t('enterprise.creator'),
    custem: () => {
      return Object.keys(enterpriseDetail.value).length ? enterpriseDetail.value.sales.params.liaison_man : '-'
    }
  },
  { // 创建人登录名
    label: t('enterprise.creator_username'),
    custem: () => {
      return Object.keys(enterpriseDetail.value).length ? enterpriseDetail.value.sales.name : '-'
    }
  },
  { // 创建人手机
    label: t('enterprise.creator_phone'),
    custem: () => {
      return Object.keys(enterpriseDetail.value).length ? enterpriseDetail.value.sales.params.liaison_phone : '-'
    }
  }
])

// 企业详情字段
const descriptions = reactive([
  { // 企业名称
    label: t('enterprise.name'),
    prop: "name",
    span: 2
  },
  { // 统一社会信用代码
    label: t('enterprise.social_code'),
    prop: "social_code",
    span: 2
  },
  { // 企业联系人姓名
    label: t('user.enterprise_Liaison'),
    prop: "liaison_man",
    custem: () => {
      return enterpriseDetail.value.params ? enterpriseDetail.value.params.liaison_man : '-'
    }
  },
  { // 企业联系人手机号
    label: t('user.enterprise_liaison_phone'),
    prop: "phone",
    custem: () => {
      return enterpriseDetail.value.params ? enterpriseDetail.value.params.phone : '-'
    }
  },
  { // 企业联系人邮箱
    label: t('user.enterprise_liaison_mail'),
    prop: "mail",
    custem: () => {
      return enterpriseDetail.value.params ? enterpriseDetail.value.params.mail : '-'
    }
  },
  { // 企业类型
    label: t('enterprise.type'),
    prop: "label",
    custem: () => {
      switch (enterpriseDetail.value.label) {
        case '0':
          return t('enterprise.general')
        case '1':
          return t('enterprise.testing')
        case '2':
          return t('enterprise.scan')
        default:
          return '-'
      }
    }
  },
  {
    label: t('user.notes'),
    prop: "comment",
    span: 2,
    custem: () => {
      return enterpriseDetail.value.params ? enterpriseDetail.value.params.comment : '-'
    }
  }
])

const addRequest = (enterpriseData) => {
  ElMessageBox.confirm(
    enterpriseData.status === 9 ? t('enterprise.reject_create_apply') : t('enterprise.pass_create_apply'),
    enterpriseData.status === 9 ? t('form.reject') : t('form.confirm'),
    {
      confirmButtonText: t('form.submit'),
      cancelButtonText: t('form.cancel'),
      type: 'warning'
    }
  )
    .then((action) => {
      if (action === 'confirm') {
        enterpriseManagement.EnterpriseUpdate(enterpriseDetail.value.id, enterpriseData)
          .then(res => {
            ElMessage.success(t('form.handleSuccess'))
            emit('getList')
            closeWin()
          })
      }
    }).catch()
}

// 确认
const onSubmit = async () => {
  await form.value.validate(valid => {
    if (!!!valid) return
    // 编辑
    addRequest({
      code: formData.value.code,
      name: formData.value.name,
      status: 1
    })
  })
}

// 拒绝
const onRefuse = async () => {
  addRequest({ status: 9 })
}

// 获取企业详情接口
const getEnterpriseDetail = (id) => {
  enterpriseManagement.EnterpriseDetail(id).then(res => {
    enterpriseDetail.value = res;
    formData.value.name = res.name
  })
}

// 打开弹框
const openWin = async (data) => {
  winShow.value = true;
  getEnterpriseDetail(data.id)
}

// 关闭弹框
const closeWin = () => {
  form.value.resetFields()
  winShow.value = false;
}

defineExpose({
  openWin: openWin
})
</script>

<style lang="scss" scoped>
:deep(.el-form-item) {
  margin-bottom: 0 !important;
}
</style>