<template>
  <el-dialog
    v-model="winShow"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onClose"
    :title="title">
    <!-- 步骤条 -->
    <el-steps v-if="!!!edit_id" :active="activeStep" align-center>
      <el-step :title="t('enterprise.create')" />
      <el-step :title="t('enterprise.authorize_product')" />
      <el-step v-if="$userRole(['GeneralAdmin'])" :title="t('enterprise.add_user')" />
    </el-steps>
    <!-- 创建企业 -->
    <div v-show="activeStep === 1">
      <EnterpriseForm
        ref="enterpriseRef"
        :edit_id="edit_id"
        :winShow="winShow"
        @onClose="onClose"
        @nextStep="nextStep" />
    </div>
    <!-- 授权产品 -->
    <div v-show="activeStep === 2">
      <EnterProForm
        ref="enterProRef"
        :winShow="winShow"
        @prevStep="prevStep"
        @nextStep="nextStep"
        @onClose="onClose" />
    </div>
    <!-- 创建企业用户 -->
    <div v-show="activeStep === 3">
      <EnterpriseUserForm
        ref="enterUserRef"
        @createEnterpise="createEnterpise"
        @prevStep="prevStep"
        @onClose="onClose"
        @getList="emit('getList')" />
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, nextTick, getCurrentInstance, computed, inject } from 'vue'
import EnterpriseUserForm from './EnterpiseUser'
import EnterpriseForm from './EnterpriseForm'
import EnterProForm from './EnterProForm'
import { productManagement, enterpriseManagement } from '@/utils/api'
import { ElMessage, ElMessageBox } from 'element-plus'

const { proxy } = getCurrentInstance()
const emit = defineEmits(['getList'])
const t = inject('t')
const winShow = ref(false)
const activeStep = ref(1) // 创建步骤条
const edit_id = ref() // 编辑
const enterpriseData = ref({}) // 企业表单数据存放
const productData = ref({}) // 产品表单数据存放
const enterpriseRef = ref() // 创建企业表单
const enterProRef = ref() // 创建企业产品表单
const enterUserRef = ref() // 创建企业用户表单
const title = computed(() => { // 弹框标题
  return edit_id.value ? t('enterprise.modify') : t('enterprise.create')
})

// 创建产品
const createProduct = async (enterprise_id, sales_id) => {
  let arr = []
  productData.value.forEach(item => {
    item = {
      product_id: item,
      enterprise_id: enterprise_id
    }
    arr.push(
      // 新增产品
      productManagement.EnterpriseProductCreate(item).then()
    )
  })
  await nextTick()
  Promise.all(arr).then(() => {
    if (proxy.$userRole(['GeneralAdmin'])) {
      enterUserRef.value.createEnterpiseUser(enterprise_id, sales_id)
    } else {
      if (proxy.$userRole(['SalesMan'])) {
        onClose()
        ElMessageBox.confirm(
          `已提交企业审核，请联系运营管理员及时审核`,
          `创建成功`,
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'success',
          }
        ).then((action) => {
          onClose()
        }).catch()
        return
      }
      ElMessage.success('创建成功')
      onClose()
    }
  })
}

// 创建企业
const createEnterpise = () => {
  if (edit_id.value) {
    // 编辑
    enterpriseManagement.EnterpriseUpdate(edit_id.value, enterpriseData.value)
      .then(res => {
        ElMessage.success('更新成功')
        emit('getList')
        onClose()
      })
  } else {
    enterpriseManagement.EnterpriseCreate(enterpriseData.value)
      .then(res => {
        createProduct(res.id, res.sales_user_id)
      })
  }
}

// 上一步
const prevStep = () => {
  activeStep.value -= 1
}

// 下一步
const nextStep = (formData) => {
  if (edit_id.value) {
    enterpriseData.value = formData.data
    createEnterpise()
  } else {
    if (formData.type === 1) {
      // 企业表单数据
      enterpriseData.value = formData.data
      activeStep.value = 2
      enterProRef.value.salesProduct(formData.data.sales_user_id)
    } else {
      // 产品数据
      productData.value = formData.data
      if (proxy.$userRole(['GeneralAdmin'])) {
        activeStep.value = 3
      } else {
        createEnterpise()
      }
    }
  }
}

// 打开弹框
const openWin = async (data = '') => {
  activeStep.value = 1
  winShow.value = true;
  if (data) {
    edit_id.value = data.id
    await nextTick()
    enterpriseRef.value.bindData(data)
  }
}

// 关闭弹框
const onClose = () => {
  switch (activeStep.value) {
    case 1:
      enterpriseRef.value.resteForm()
      break;
    case 2:
      enterpriseRef.value.resteForm()
      enterProRef.value.resteForm()
      break;
    case 3:
      enterpriseRef.value.resteForm()
      enterProRef.value.resteForm()
      enterUserRef.value.resteForm()
      break;
    default:
      break;
  }
  winShow.value = false;
  emit('getList')
}

defineExpose({
  openWin: openWin
})
</script>
