<template>
	<div class="list-tool-bar">
		<el-button-group class="action">
			<el-button @click="emit('onCreate')" type="primary">{{ t('table.create') }}</el-button>
		</el-button-group>
		<div class="search">
			<div class="search_item search_input">
        <el-input
          v-model="searchText"
          @input.native="doSearch"
          :prefix-icon="Search"
          :placeholder="t('form.search_placeholder')"
          class="search-input"
          clearable>
          <template #prepend>
            <el-select v-model="searchType"  style="width: 115px">
              <el-option :label="t('enterprise.name')" value="1" />
              <el-option :label="t('enterprise.code')" value="2" />
            </el-select>
          </template>
        </el-input>
			</div>
			<div class="search_item" v-if="$userRole(['SuperAdmin', 'GeneralAdmin'])">
				<el-select v-model="searchData.filter_sales_user_id" filterable @change="doSearch" clearable :placeholder="t('user.sales')">
					<el-option v-for="(item, index) in salesList" :key="index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, onMounted, inject } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { getUserList } from '@/utils/common'

const emit = defineEmits(['getList', 'onCreate'])
const searchData = ref({})
const salesList = ref([])
const searchType = ref('1')
const searchText = ref('')
const t = inject('t')

onMounted(async () => {
	salesList.value = await getUserList({ filter_role_alias: 'SalesMan' })
})

// 搜索事件
const doSearch = () => {
	if (searchType.value === '1') {
		searchData.value.search = searchText.value
		searchData.value.filter_code = ''
	} else {
		searchData.value.search = ''
		searchData.value.filter_code = searchText.value
	}
	for (let key in searchData.value) {
		if (!!!searchData.value[key]) {
			delete searchData.value[key]
		}
	}
  searchData.value.page = 1
	emit('getList', searchData.value)
}
</script>
